@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
*{
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  color: #FFFFFF;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
:root{
  --bg1: #191919;
  --bg2: #202020;
}
body{
  margin: 0;
  background-color: var(--bg1);
}
body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: var(--bg1);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--bg2);
  border-radius: 20px;
  border: 3px solid var(--bg1);
}
.conteiner{
  margin: auto;
  max-width: 1140px;
  padding: 0px 10px;
}
.title{
  font-weight: 700;
  font-size: 58px;
}
.desc{
  color: #A6A6A6;
  margin-top: 20px;
  font-weight: 400;
  max-width: 672px;
  font-size: 24px;
}
.color-bclok{
  background-color: var(--bg2);
}
.padd-block{
  padding: 130px 0px;
}
.links{
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 44px;
}

/* Header*/
.header{
  z-index: 2;
  position: fixed;
  width: 100%;
  transition: 200ms;
}
.main-start-left-desc{
  max-width: 530px;
}
.header-bg2{
  background-color: var(--bg2) !important;
  transition: 200ms;
}
.header-logo-img{
  width: 90px;
  transition: 200ms;
}
.header-burger{
  display: none;
}
.header-inner{
  height: 100px;
  transition: 200ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-links{
  align-items: center;
  display: flex;
}
.header-links-soc{
  align-items: center;
  display: flex;
}
.header-link{
  margin: 0px 15px;
}
.header-link-soc{
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0px 6px;
}

/* Main */
.main-costomers-content-left-desc{
  max-width: 409px;
}
.main-contact-title-desc{
  max-width: 350px;
}
.start-blure{
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px) brightness(60%);
  padding-bottom: 20px;
}
.main-services-content-left-title-desc{
  max-width: 470px;
}
.main-start{
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  border-radius: 0px 0px 20px 20px;
}
.video-fon{
  position: absolute;
  z-index: 0;
  width: 100%;
}
.main-start-inner{
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
}
.main-start-img{
  width: 100%;
  max-width: 499px;
}
.button{
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  border-radius: 10px;
  padding: 14px 0px;
  text-align: center;
  outline: none;
  border: none;
  width: 235px;
  cursor: pointer;
}
.oneButton{
  background: #191919;
}
.twoButton{
  background: #2C2C2C;
}
.main-start-btn{
  margin-top: 70px;
}
.main-services-list-item{
  background-color: var(--bg2);
  border-radius: 10px;
  height: 96px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 32px;
}
.main-services-list-item-active{
  height: 100%;
}
.main-services-list-item-top{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 32px 52px ;
}
.main-services-list-item-top-title{
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
.main-services-list-item-bottom{
  white-space: pre-wrap;
  padding: 0px 52px 32px 52px;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #A6A6A6;
}
.main-parners-list-item-img{
  width: 100%;
}
.main-services-list{
  margin-top: 70px;
}
.main-services-list-item-top-arrow{
  transition: 200ms;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}
.main-services-list-item-active > .main-services-list-item-top > .main-services-list-item-top-arrow{
  transform: scale(1, -1);
  transition: 200ms;
}
.main-hr-list{
  margin-top: 70px;
}
.main-hr-list-item{
  width: 320px;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg1);
  border-radius: 20px;
  padding: 32px 25px;
  margin: 0px 0px 48px 0px;
}
.main-hr-list-item-image{
  width: 100%;
  height: 133px;
  background-image: url('../image/Group\ 44.svg');
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-hr-list-item-img{
  background-size: cover;
  background-position: center;
  width: 121px;
  height: 122px;
  border-radius: 20px;
}
.main-hr-list-item-name{
  font-weight: 500;
  font-size: 16px;
}
.main-hr-list-item-title{
  font-weight: 700;
  font-size: 16px;
}
.main-hr-list-item-desc{
  font-weight: 400;
  height: 62px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #A6A6A6;
  padding: 0px 20px;
}
.main-hr-list-cv{
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  padding: 5px 22px;
  text-decoration: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
}
.main-hr-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-hr-list-facke-item{
  width: 320px;
}
.main-btn{
  text-align: center;
  margin-top: 20px;
}
.main-pricing-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-pricing-btn{
  margin-top: 60px;
}
.main-parners-list{
  margin-top: 70px;
}
.main-parners-list-item{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  min-height: 410px;
  background-color: var(--bg1);
  width: 320px;
  border-radius: 20px;
  padding: 21px 35px 21px 56px;
  margin-bottom: 55px;
}
.main-parners-list-item-link{
  display: flex;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
}
.main-parners-list-item-link-arrow{
  margin-left: 8px;
}
.main-parners-list-item-desc{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  word-wrap: break-word;
  color: #A6A6A6;
  max-height: 80px;
  overflow: hidden;
  margin-top: 12px;
}
.main-parners-list-item-title{
  font-weight: 700;
  margin-top: 15px;
  word-wrap: break-word;
  font-size: 24px;
  line-height: 32px;
}
.main-parners-list-item-image{
  height: 170px;
  width: 150px;
  overflow: hidden;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-parners-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-parners-list-facke{
  width: 320px;
}
.main-costomers-list{
  margin-top: 70px;
}
.main-costomers-list-item{
  width: 187px;
  text-align: center;
  margin: 0px 0px 80px 0px;
}
.main-costomers-list-facke{
  width: 187px;
}
.main-costomers-list-item-img{
  width: 85%;
}
.main-costomers-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-contact-info-item{
  width: 652px;
  align-items: center;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
}
.main-contact-info{
  margin-top: 80px;
}
.main-contact-info-item-phone{
  display: flex;
  align-items: center;
}
.main-contact-info-item-phone-title{
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
  margin-left: 21px;
}
.main-contact-info-item-link{
  margin: 0px 16px;
}
.main-contact-info-item-mail{
  margin-top: 70px;
}
.redBtn{
  background-color: #561B1B;
}
.main-contact-redButton-desc{
  margin-top: 38px;
}
.main-costomers-content-left-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-services-content-left-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-parners-content-left-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-error{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Left Panel */
.left-panel-block{
  padding-left: 20px;
  position: fixed;
  width: 70%;
  transition: 200ms;
  height: 100vh;
  right: -100%;
  z-index: 11;
  background-color: var(--bg2);
}
.left-panel-actove > .left-panel-block{
  right: 0;
  transition: 200ms;
}

.left-panel-actove{
  width: 100%;
  backdrop-filter: brightness(60%);
  height: 100vh;
  position: fixed;
  z-index: 10;
}
.left-panel-list-last{
  height: 180px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.left-panel-list{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 180px);
  margin-top: 20px;
}
.left-panel-burg{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: right;
}

/* Footer */
.footer{
  padding: 90px 0px 10px 0px;
}
.footer-inner{
  display: flex;
  justify-content: space-between;
}
.footer-left-inner{
  display: flex;
}
.footer-left-links{
  display: flex;
  flex-direction: column;
  margin-left: 65px;
}
.footer-left-link{
  margin: 6px;
}
.linksMail{
  text-decoration: none;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
}
.footer-left-logo{
  margin-right: 40px;
}
.footer-right{
  margin-top: 20px;
}
.footer-right-item{
  margin-bottom: 39px;
}
.footer-right-links{
  margin-top: 24px;
}
.footer-right-powered{
  opacity: 0.5;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  margin: auto;
  line-height: 44px;
}
.main-bottom{
  text-align: center;
}
.footer-right-phone-title{
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
}
.footer-rigths{
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 44px;
  opacity: 0.5;
}
.cards{
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to top ,var(--bg1)  55%, #2C2C2C 45%);
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-block{
  min-height: 838px;
  border-radius: 20px;
  overflow: hidden;
  max-width: 680px;
  width: 100%;
  height: 100%;
  max-height: 869px;
  background: linear-gradient(to bottom, #202020 50%, #2C2C2C 50%);
}
.cards-block-top{
  background: #202020 ;
  padding-top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.cards-block-button{
  background-color: #2C2C2C;
  padding: 60px;
}
.cards-block-img{
  width: 120px;
  height: 120px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.cards-block-btns{
  display: flex;
  margin-top: 23px;
}
.cards-block-btn{
  display: flex;
  padding: 13px 11px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}
.cards-block-btn > span{
  margin-left: 10px;
}
.cards-block-btn:nth-child(2n){
  margin-left: 10px;
}
.cards-block-btn:last-child{
  margin-left: 10px;
}
.cards-block-navs{
  display: flex;
  margin-top: 43px;
}
.cards-block-nav{
  cursor: pointer;
  padding: 7px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 5px 5px 0px 0px;
}
.cards-block-nav-active{
  background: #2C2C2C;
}
.cards-block-prof{
  color: #adadad;
  font-weight: 100;
}
.cards-block-name{
  margin-top: 14px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}
.cards-block-button-link-img{
  width: 60px;
}
.cards-block-button-links{
  width: 100%;
  justify-content: space-around;
  display: flex;
  margin-top: 40px;
}
.contact-link{
  width: 100%;
  display: flex;
  padding: 18px 0px;
  border-bottom: 1px solid #3A3A3A;
}
.contact-link:last-child{
  border-bottom: 1px solid #ffffff00;
}
.contact-link-right-title{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.contact-link-right-desc{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
}
.contact-link-right{
  margin-left: 16px;
}
.contact-link-left-img{
  width: 23px;
}
.bg-popUp{
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #00000077;
  z-index: 15;
}
.popUp{
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 14;
  display: none;
}
.popUp-inner{
  width: 100%;
  height: 100vh;
  position: relative;
}
.popUp-block{
  max-width: 410px;
  padding: 65px 65px 48px 65px;
  width: 100%;
  background: #2C2C2C;
  border-radius: 20px;
  z-index: 16;
  position: relative;
}
.popUp-block-inner{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popUp-block-item{
  display: flex;
  align-items: center;
  margin-top: 0;
}
.popUp-block-item-link{
  font-weight: 400;
  font-size: 30px;
  margin-left: 20px;
  line-height: 32px;
}
.popUp-block-clouse{
  position: absolute;
  top: 24px;
  cursor: pointer;
  right: 24px;
}
.popUp-block-item-2{
  margin-top: 45px;
}
.red-conteiner{
  display: flex;
}
.video{
  width: 50%;
  margin-left: 20px;
  border-radius: 20px;
  overflow: hidden;
}
.video-block{
  width: 100%;
  display: none;
}
.home-error-title{
  font-size: 22px;
}
.cards-block-buton-logo{
  position: relative;
  text-align: center;
  width: 100%;
  height: 48px;
}
.cards-block-buton-logo-img{
  width: 28px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/* Adaptation */
@media (max-width: 1140px) {
  .header-link{
    margin: 0px 5px;
  }
  .header-link-soc{
    margin: 0px 3px;
  }
  .main-hr-list-item{
    width: 300px;
  }
  .main-hr-list-item-image{
    background-size: contain;
  }
  .main-hr-list-item-img{
    width: 119px;
    height: 120px;
  }
  .footer-left-links{
    margin-left: 30px;
  }
  .main-parners-list-item{
    width: 300px;
  }
  .main-parners-list-item-image{
    margin-left: 22px;
  }
  .main-parners-list-facke{
    width: 300px;
  }
  .main-hr-list-facke-item{
    width: 300px;
  }
}
@media (max-width: 960px) {
  .red-conteiner{
    display: block;
  }
  .video{
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
  }
  .main-start{
    background-image: url('../image/Group 77.png');
  }
  .header-links{
    display: none;
  }
  .main-start-img{
    margin-top: 30px;
  }
  .main-start-btn{
    margin-top: 20px;
  }
  .header-links-soc{
    display: none;
  }
  .main-start-inner{
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .main-start-right{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main-services-content{
    display: block;
  }
  .main-pricing-inner{
    display: block;
  }
  .main-parners-content{
    display: block;
  }
  .main-costomers-content{
    display: block;
  }
  .main-pricing-right{
    margin-top: 80px;
  }
  .main-hr-list-item{
    width: 272px;
    height: 360px;
  }
  .main-hr-list-item-name{
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
  }
  .main-hr-list-item-title{
    font-weight: 700;
    font-size: 14px;
    line-height: 29px;
  }
  .main-hr-list-item-desc{
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    padding: 0;
  }
  .main-hr-list-cv{
    width: 100%;
  }
  .main-hr-list-item-img{
    width: 108px;
    height: 109px;
  }
  .main-hr-list-item-image{
    height: 109px;
  }
  .main-pricing-right{
    width: 100%;
    text-align: center;
  }
  .main-pricing-right-img{
    width: 85%;
  }
  .main-parners-list-item{
    width: 272px;
    padding: 21px 21px 21px 35px;
  }
  .main-parners-list-item-image{
    margin-left: 28px;
  }
  .main-parners-list-facke{
    width: 272px;
  }
  .main-hr-list-facke-item{
    width: 272px;
  }
  .main-services-content-left-title{
    display: flex;
    justify-content: space-between;
  }
  .main-pricing-left-title{
    display: flex;
    justify-content: space-between;
  }
  .main-contact-info-item-phone-title{
    font-size: 32px;
  }
  .main-contact-info-item{
    width: 100%;
  }
  .footer-left-logo{
    text-align: center;
  }
  .footer-inner{
    margin-top: 40px;
  }
  .links{
    font-weight: 300;
    font-size: 32px;
  }
  .footer-right-phone-title{
    font-size: 32px;
  }
  .footer-right{
    display: flex;
    flex-direction: column;
  }
  .footer-right-links{
    display: flex;
    justify-content: right;
  }
  .linksMail {
    text-align: right;
  }
  .footer-right-powered{
    text-align: right;
  }
  .main-parners-content-left-title{
    display: flex;
    justify-content: space-between;
  }
  .footer-left-logo{
    margin-right: 0;
  }
  .header-burger{
    display: block;
  }
  .header-link-soc{
    width: 41px;
  }
  .conteiner{
    padding: 0px 33px;
  }
}
@media (max-width: 834px) {
  .main-hr-list{
    justify-content: space-evenly;
  }
  .main-parners-list{
    justify-content: space-evenly;
  }
  .main-costomers-list{
    justify-content: space-evenly;
  }
}
@media (max-width: 640px) {
  .popUp-block{
    padding: 40px 30px 20px 30px;
  }
  .popUp-block-item-link{
    font-size: 22px;
    margin-left: 10px;
  }
  .popUp-block-item-img{
    width: 23px;
  }
  .cards-block-button{
    padding: 30px;
  }
  .cards-block{
    border-radius: 0px;
  }
  .footer-left-logo-img{
    width: 54px;
  }
  .header-logo-img{
    width: 44px !important;
  }
  .header-link-soc{
    width: 22px;
  }
  .left-panel-burger-cloused{
    width: 22px;
  }
  .header-burger{
    width: 22px;
  }
  .main-start-right{
    display: block;
  }
  .title{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  .desc{
    margin-top: 9px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  .header-inner{
    height: 60px !important;
  }
  .left-panel-burg{
    height: 60px;
  }
  .main-start-inner{
    justify-content: center;
    align-items: start;
  }
  .main-services-list-item-top-title{
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
  .main-services-list-item-top{
    padding: 8px 21px;
  }
  .main-services-list-item{
    margin-bottom: 16px;
    height: 48px;
  }
  .main-services-list-item-active{
    height: 100%;
  }
  .main-services-list-item-bottom{
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 21px;
  }
  .main-services-list-item-top-arrow-img{
    width: 12px;
  }
  .button{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .footer-left-link{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  .footer-right-phone-title{
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }
  .main-contact-info-item-link-img{
    width: 20px;
  }
  .main-contact-info-item-phone > img{
    width: 20px;
  }
  .main-contact-info-item-link{
    margin: 0px 8px;
  }
  .footer-right-powered{
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
  }
  .footer-right-links{
    margin-top: 10px;
  }
  .footer-right-item{
    margin-bottom: 10px;
  }
  .footer-left-links{
    margin-left: 0;
  }
  .footer-right{
    margin-top: 0;
  }
  .footer-rigths{
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
  }
  .button{
    width: 100%;
    margin-bottom: 15px;
  }
  .main-parners-list-item{
    margin-bottom: 24px;
  }
  .conteiner{
    padding: 0px 15px;
  }
  .main-pricing-right{
    margin-bottom: 30px;
  }
  .main-hr-list-item{
    margin-bottom: 24px;
  }
  .padd-block{
    padding: 50px 0px;
  }
  .main-services-list{
    margin-top: 17px;
  }
  .main-hr-list{
    margin-top: 40px;
  }
  .main-parners-list{
    margin-top: 40px;
  }
  .main-start-btn{
    margin-top: 15px;
  }
  .footer{
    padding: 50px 0px 10px 0px;
  }
  .main-contact-info-item-phone-title{
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }
  .main-contact-info-item-phone-title{
    margin-left: 10px;
  }
  .main-contact-info{
    margin-top: 24px;
  }
  .main-contact-info-item-mail{
    margin-top: 10px;
  }
  .main-contact-info-item{
    margin-bottom: 10px;
  }
  .main-costomers-list-item{
    width: 145px;
  }
  .main-costomers-list-facke{
    width: 145px;
  }
  .header-link{
    font-size: 20px;
  }
  .left-panel-block{
    padding-left: 10px;
  }
}